var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "考试基本信息",
        },
        on: {
          "head-save": _vm.headSave,
          "head-save-back": function ($event) {
            return _vm.headSave(true)
          },
          "head-submit": _vm.headSubmit,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
        on: {
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
      _c("head-layout", {
        attrs: { "head-title": "参考人员", "head-btn-options": _vm.headBtn },
        on: {
          "head-add-tabs": _vm.headAddTabs,
          "head-remove-tabs": _vm.headRemoveTabs,
        },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoadExamUser,
          "gird-handle-select-click": _vm.selectionChange,
        },
      }),
      _vm.roleBox
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "90%",
                dialogTitle: _vm.$t("cip.plat.sys.user.field.roleConfig"),
              },
              on: {
                cancel: function ($event) {
                  _vm.roleBox = false
                },
                confirm: _vm.submitBox,
              },
            },
            [
              _vm.roleBox
                ? _c("departmentPersonnel", {
                    ref: "departmentPersonnel",
                    attrs: { "disabled-list": _vm.disabledErpItemList },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "avue-dialog avue-dialog--top examPaper",
          attrs: {
            title: "试卷列表",
            visible: _vm.dialogVisible,
            "append-to-body": "",
            "close-on-click-modal": false,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("exampaper-dialog", {
                on: { getSelectionData: _vm.getExamPaperData },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }